import React from "react";
import "./LoaderLogo.css";
import logo from './logo.gif'; 

class LogoLoader extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="logo-container">
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </div>
    );
  }
}

export default LogoLoader;
