/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Juan Sepúlveda Portfolio",
  description:
    "Soy un solucionador de problemas creativo y comprometido, capaz de trabajar tanto de forma independiente como en equipo. Constantemente busco aprender nuevas tecnologías y mejorar mis habilidades técnicas, con una sólida ética de trabajo y una pasión por la innovación.",
  og: {
    title: "Juan Pablo Sepulveda",
    type: "website",
    url: "http://sepulveda.com.ar/",
  },
};

//Home Page
const greeting = {
  title: "Juan Pablo Sepúlveda",
  logo_name: "juan Sepulveda",
  nickname: "juansepu96",
  subTitle:
    "Soy un solucionador de problemas creativo y comprometido, capaz de trabajar tanto de forma independiente como en equipo. Constantemente busco aprender nuevas tecnologías y  mejorar mis habilidades técnicas, con una sólida ética de trabajo y una pasión por la innovación.", 
  resumeLink:
    "https://sepulveda.com.ar/cert/Sepulveda Juan CV.pdf",
  portfolio_repository: "https://github.com/juansepu96",
  githubProfile: "https://github.com/juansepu96",
};

const socialMediaLinks = [  {
    name: "Github",
    link: "https://github.com/juansepu96",
    fontAwesomeIcon: "fa-github", 
    backgroundColor: "#181717", 
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/juansepu96/",
    fontAwesomeIcon: "fa-linkedin-in", 
    backgroundColor: "#0077B5", 
  },
  {
    name: "Gmail",
    link: "mailto:juan@sepulveda.com.ar",
    fontAwesomeIcon: "fa-google", 
    backgroundColor: "#D14836", 
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/juansepu96",
    fontAwesomeIcon: "fa-x-twitter", 
    backgroundColor: "#000000", 
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/juansepu96/",
    fontAwesomeIcon: "fa-facebook-f", 
    backgroundColor: "#1877F2", 
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/juansepu96/",
    fontAwesomeIcon: "fa-instagram", 
    backgroundColor: "#E4405F", 
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Developer",
      fileName: "FullStackImg",
      skills: [
        "👨‍💻 Desarrollo de Plataformas Web Responsive como Tiendas Online y Sitios Web en React, Angular, HTML, entre otros.",
        "📱 Aplicaciones Móviles en React Native & Flutter (Dart).",
        "📈 Lenguajes Backend como Laravel - CakePHP (PHP), Node / Express (Js) & Python.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "devicon:php",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Laravel",
          fontAwesomeClassname: "logos:laravel",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "CakePHP",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "skill-icons:angular-dark",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Automatización y Gestión",
      fileName: "DataScienceImg",
      skills: [
        "📊 Estandarización y automatización de procesos enfocada en PYMES y Entidades Goburnamentales. ",
        "🔗 Integración con servicios externos (API) de pasarelas de pago, empresas de correo y logística, entre otros.",
        "🤖 Aplicación de IA a procesos internos mediante Chatbots por WhatsApp.",
        "📉 Análisis de Ingresos / Egresos para el apoyo de la toma de decisiones.",
        "📁	Digitalización de la documentación alojada en Cloud Computing.",
        "🏦 Simplificación de procesos tributarios.",

      ],
      softwareSkills: [
        {

        }
      ],
    },    
    {
      title: "Infraestructura Cloud Computing",
      fileName: "CloudInfraImg",
      skills: [
        "☁ Experiencia trabajando en múltiples plataformas Cloud Computing.",
        "🖥 Alojamiento y mantenimiento de sitios web en instancias de máquinas virtuales junto con la integración de bases de datos.",
        "🤖 Implementación de modelos de aprendizaje profundo en la nube.",
        "⌨ Configuración de servicios de AWS como Lambda, S3, SES, SNS.",
        "💽 Bases de Datos SQL & No-SQL.",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "logos:mysql",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Lambda",
          fontAwesomeClassname: "logos:aws-lambda",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "S3",
          fontAwesomeClassname: "logos:aws-s3",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "SNS",
          fontAwesomeClassname: "logos:aws-sns",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "Diseño UX/UI",
      fileName: "DesignImg",
      skills: [
        "✏ Diseño de interfaces de usuario altamente atractivas para aplicaciones móviles y web.",
        "📝 Personalización de diseños de logotipos y creación de logotipos desde cero.",
        "♻ Creación el flujo de funcionalidades para optimizar la experiencia del usuario.",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Técnico Superior en Análisis de Sistemas ",
      subtitle: "Instituto Superior Juan XXIII - Bahía Blanca, Buenos Aires.",
      logo_path: "juan23.png",
      alt_name: "",
      duration: "2015 - 2023",
      descriptions: [
        "🧑‍💻 Fundamentos de Informática y Programación: Introducción a la informática y lenguajes de programación como Python y Java.",
        "💽 Bases de Datos: Diseño y gestión de bases de datos relacionales, incluyendo SQL.",
        "⌨ Desarrollo de Software: Metodologías de desarrollo de software y aseguramiento de la calidad.",
        "📊 Análisis y Diseño de Sistemas: Técnicas de análisis de requisitos y modelado de sistemas con UML.",
        "🌐 Redes y Comunicaciones: Conceptos y gestión de redes de computadoras y seguridad en redes.",
        "📂 Gestión de Proyectos y Negocios: Principios de gestión de proyectos y estrategias de TI alineadas con objetivos empresariales.",
      ],
      website_link: "https://juan23.edu.ar",
    },
    {
      title: "Bachiller en Ciencias Naturales",
      subtitle: "Escuela de Educación Secundaria Nº1 Ing. Esteban Dufaur - Monte Hermoso, Buenos Aires.",
      logo_path: "es1.png",
      alt_name: "",
      duration: "2009 - 2014",
      descriptions: [
        "🧬 Participación Feria de Ciencias Exactas 2012, 2013 y 2014. Proyecto 'Recitren' de la asignatura Informática logrando menciones a nivel provincial.",
        "➕ Participación en la Olimpiada de Matematicas Nacional - Mención Especial.",
        "👥 Participación en Parlamentos Juveniles del YMCA.",
        "🧑‍🤝‍🧑 Proyecto 'Prevenir en Salvar Vidas' en conjunto con el Instituto de Prevención de Adicciones de la Provincia de Buenos Aires.",

      ],
      website_link: "",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Desarrollo Web Full Stack - Bootcamp",
      subtitle: "Soy Henry",
      logo_path: "henry.png",
      certificate_link:
        "#",
      alt_name: "Soy Henry",
      color_code: "#000000",
    },
    {
      title: "React JS + Redux + ES6",
      subtitle: "Ing. Emiliano Ocariz",
      logo_path: "udemy.jpg",
      certificate_link:
        "https://sepulveda.com.ar/cert/udemy1.jpg",
      alt_name: "Udemy",
      color_code: "#FFFFFF",
    },
    {
      title: "React JS + Redux + ES6",
      subtitle: "Ing. Emiliano Ocariz",
      logo_path: "udemy.jpg",
      certificate_link:
        "https://sepulveda.com.ar/cert/udemy1.jpg",
      alt_name: "Udemy",
      color_code: "#FFFFFF",
    },
    {
      title: "PHP & MySQL",
      subtitle: "Itr. Carlos Arturo Esparza",
      logo_path: "udemy.jpg",
      certificate_link:
        "https://sepulveda.com.ar/cert/udemy2.jpg",
      alt_name: "Udemy",
      color_code: "#FFFFFF",
    },
    {
      title: "Master en JavaScript - jQuery, Angular, NodeJS",
      subtitle: "Ing. Victor Robles",
      logo_path: "udemy.jpg",
      certificate_link:
        "https://sepulveda.com.ar/cert/udemy3.jpg",
      alt_name: "Udemy",
      color_code: "#FFFFFF",
    },
    {
      title: "Bootstrap Full Course",
      subtitle: "Dr. Bairon Londoño Gonzalez",
      logo_path: "edutin.webp",
      certificate_link:
        "https://sepulveda.com.ar/cert/edutin.pdf",
      alt_name: "Edutin",
      color_code: "#FFFFFF",
    },
    {
      title: "Diseño Web",
      subtitle: "Carla Navarro Prentt",
      logo_path: "edutin.webp",
      certificate_link:
        "https://sepulveda.com.ar/cert/edutin2.pdf",
      alt_name: "Edutin",
      color_code: "#FFFFFF",
    },
    {
      title: "Liderazgo Juvenil",
      subtitle: "Melissa Ferrofino",
      logo_path: "jci.png",
      certificate_link:
        "https://sepulveda.com.ar/cert/jci.pdf",
      alt_name: "JCI",
      color_code: "#FFFFFF",
    },
    {
      title: "Analisis Y Reparación de PC",
      subtitle: "Roman Ksibala",
      logo_path: "quantum.png",
      certificate_link:
        "https://sepulveda.com.ar/cert/quantum.jpg",
      alt_name: "Quantum Computación",
      color_code: "#FFFFFF",
    },
    {
      title: "Calidad en la Atención al Cliente",
      subtitle: "Gonzalo Casanova Ferro",
      logo_path: "min.png",
      certificate_link:
        "https://sepulveda.com.ar/cert/min1.jpg",
      alt_name: "Ministerio de Turismo de la Nación",
      color_code: "#FFFFFF",
    },
    {
      title: "Gestión de Calidad",
      subtitle: "Gonzalo Casanova Ferro",
      logo_path: "min.png",
      certificate_link:
        "https://sepulveda.com.ar/cert/min3.jpg",
      alt_name: "Ministerio de Turismo de la Nación",
      color_code: "#FFFFFF",
    },
    {
      title: "Inglés Técnico I y II",
      subtitle: "Gonzalo Casanova Ferro",
      logo_path: "min.png",
      certificate_link:
        "https://sepulveda.com.ar/cert/min5.jpg",
      alt_name: "Ministerio de Turismo de la Nación",
      color_code: "#FFFFFF",
    }
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Machine Learning Engineer",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "June 2023 - Present",
          location: "San Jose, CA, USA",
          description:
            "Improving ads ranking models on the core TikTok product. Experience working on modeling two-tower architectures like DeepFM, Wide & deep learning, etc. Working on Large Language Models (LLM) pretraining and Large Multi-modal Model (LMM) finetuning strategies.",
          color: "#000000",
        },
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - Aug 2021",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "San Francisco, USA",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contactame",
    profile_image_path: "juan.png",
    description:
      "Estoy disponible en casi todas las redes sociales. Podés enviarme un mensaje y te responderé cuando antes me sea posible.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Correo Electrónico",
    subtitle: "juan@sepulveda.com.ar",
    locality: "Bahía Blanca",
    country: "Argentina",
    region: "Buenos Aires",
    postalCode: "8000",
    streetAddress: "",
    avatar_image_path: "address_image.svg",
    location_map_link: "#",
  },
  phoneSection: {
    title: "Teléfono",
    subtitle: "+54 9 291 4716316",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
